// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Chivo:400,700,900);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".exporter .btn {\n  margin-left: 30px;\n  padding: 10px 0;\n}\n.exporter .btn.btn-link {\n  margin-right: 64px;\n}\n.exporter .btn.btn-link:hover {\n  text-decoration: none;\n}", "",{"version":3,"sources":["webpack://./src/components/common/exportPdf/styles/_index.scss"],"names":[],"mappings":"AAGE;EACE,iBAAA;EACA,eAAA;AADJ;AAGI;EACE,kBAAA;AADN;AAGM;EACE,qBAAA;AADR","sourcesContent":["@import '~Styles/general/_variables';\n\n.exporter {\n  .btn {\n    margin-left: 30px;\n    padding: 10px 0;\n\n    &.btn-link {\n      margin-right: 64px;\n\n      &:hover {\n        text-decoration: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
