// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Chivo:400,700,900);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal .modal-dialog.player-page .modal-content .modal-body .secondary-page.stats-page .dash-circle {\n  margin-left: 7px;\n}", "",{"version":3,"sources":["webpack://./src/components/prospectsPlayerPage/components/pages/stats/styles/_index.scss"],"names":[],"mappings":"AASc;EACE,gBAAA;AAPhB","sourcesContent":["@import '~Styles/general/_variables';\n\n.modal {\n  .modal-dialog {\n    &.player-page {\n      .modal-content {\n        .modal-body {\n          .secondary-page {\n            &.stats-page {\n              .dash-circle {\n                margin-left: 7px;\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
