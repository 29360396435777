// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Chivo:400,700,900);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filter.positions .collapse .btn,\n.filter.positions .collapsing .btn {\n  color: #ffffff;\n  display: block;\n  font-size: 0.75rem;\n  padding: 7px 4px;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/components/common/filters/filters/components/specific/positions/styles/_index.scss"],"names":[],"mappings":"AAMM;;EACE,cAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;AAHR","sourcesContent":["@import '~Styles/general/_variables';\n\n.filter {\n  &.positions {\n    .collapse,\n    .collapsing {\n      .btn {\n        color: #ffffff;\n        display: block;\n        font-size: 0.75rem;\n        padding: 7px 4px;\n        text-align: center;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
