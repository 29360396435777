import {
  ContactInfoBodyEmailItem,
  ContactInfoBodyPhoneItem,
  ContactInfoBodyAddressItem,
  ContactInfoBodyBasicItem,
  ContactInfoBodyCollegeAlmaMaterItem,
} from './contactInfoBodyItem';

export {
  ContactInfoBodyEmailItem,
  ContactInfoBodyPhoneItem,
  ContactInfoBodyAddressItem,
  ContactInfoBodyBasicItem,
  ContactInfoBodyCollegeAlmaMaterItem,
};
