import {
  ContactInfoLocation,
  ContactInfoValue,
  ContactInfoIcon,
  ContactInfoDate,
  ContactInfoBirthDate,
  ContactInfoPhone,
  ContactInfoPhoneNumbers,
  ContactInfoState,
  ContactInfoAlmaMater,
  ContactInfoSocial,
  ContactInfoCollege,
} from './contactInfoMisc';

export {
  ContactInfoLocation,
  ContactInfoValue,
  ContactInfoIcon,
  ContactInfoDate,
  ContactInfoBirthDate,
  ContactInfoPhone,
  ContactInfoPhoneNumbers,
  ContactInfoState,
  ContactInfoAlmaMater,
  ContactInfoSocial,
  ContactInfoCollege,
};
