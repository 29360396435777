// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Chivo:400,700,900);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rivals-camp > .page-content {\n  min-height: calc(100% - 65px);\n}\n.rivals-camp > .page-content .col-md-8 {\n  padding: 50px 40px 0;\n}\n.rivals-camp .utility-bar .dropdown-filter .btn {\n  background-image: none;\n  padding: 10px 16px 10px 16px;\n}\n.rivals-camp .utility-bar .dropdown-filter .btn .icon {\n  margin-left: 24px;\n}", "",{"version":3,"sources":["webpack://./src/components/rivalsCamp/styles/_index.scss"],"names":[],"mappings":"AAGE;EACE,6BAAA;AADJ;AAGI;EACE,oBAAA;AADN;AAKE;EACE,sBAAA;EACA,4BAAA;AAHJ;AAKI;EACE,iBAAA;AAHN","sourcesContent":["@import '~Styles/general/_variables';\n\n.rivals-camp {\n  > .page-content {\n    min-height: calc(100% - 65px);\n\n    .col-md-8 {\n      padding: 50px 40px 0;\n    }\n  }\n\n  .utility-bar .dropdown-filter .btn {\n    background-image: none;\n    padding: 10px 16px 10px 16px;\n\n    .icon {\n      margin-left: 24px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
