// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Chivo:400,700,900);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rivals-camp .page-header-container .page-header .ncsa-logo {\n  margin-right: 12%;\n  width: 8rem;\n  z-index: 2;\n}\n.rivals-camp .page-header-container .utility-bar .col-md-8 {\n  padding: 0 50px;\n}\n.rivals-camp .page-header-container .utility-bar .rivals-camp-nav-filters {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  width: 100%;\n}\n.rivals-camp .page-header-container .utility-bar .rivals-camp-nav-filters .dropdown-filter {\n  margin-right: 5px;\n}\n.rivals-camp .page-header-container .utility-bar .rivals-camp-nav-filters .input {\n  width: 30%;\n}\n.rivals-camp .page-header-container .utility-bar .rivals-camp-nav-filters .input input {\n  height: 44px;\n}", "",{"version":3,"sources":["webpack://./src/components/rivalsCamp/components/header/styles/_index.scss"],"names":[],"mappings":"AAKM;EACE,iBAAA;EACA,WAAA;EACA,UAAA;AAHR;AAQM;EACE,eAAA;AANR;AASM;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,WAAA;AAPR;AASQ;EACE,iBAAA;AAPV;AAUQ;EACE,UAAA;AARV;AAUU;EACE,YAAA;AARZ","sourcesContent":["@import '~Styles/general/_variables';\n\n.rivals-camp {\n  .page-header-container {\n    .page-header {\n      .ncsa-logo {\n        margin-right: 12%;\n        width: 8rem;\n        z-index: 2;\n      }\n    }\n\n    .utility-bar {\n      .col-md-8 {\n        padding: 0 50px;\n      }\n\n      .rivals-camp-nav-filters {\n        display: flex;\n        align-items: center;\n        justify-content: flex-end;\n        width: 100%;\n\n        .dropdown-filter {\n          margin-right: 5px;\n        }\n\n        .input {\n          width: 30%;\n\n          input {\n            height: 44px;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
